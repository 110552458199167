import { useContext } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import useSWR from "swr";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";

import DescriptionHolder from "../../components/DescriptionHolder";
import Loader from "../../components/Loader";
import BeefProductBrand from '../../components/BeefProductBrand';

import RetailerContext from "../../context";

import { getTranslation } from "../../utils/translate";
import { getBeefProductOverride } from '../../utils/overrides';
import constants from "../../utils/constants";
import { fetcher } from "../../utils/fetcher";

import AccordionSection from "../../components/AccordionSection";
import BeefCuts from "../../components/Collection/BeefCuts";
import Wysiwyg from "../../components/Wysiwyg";
import Nutrition from "../../components/Nutrition";
import Videos from "../../components/Videos";
import TileCard from "../../components/TileCard";

import { useUpdateDocumentMeta } from "../../hooks/useUpdateDocumentMeta";

import styles from './index.module.scss';
import PageHeader from "../../components/PageHeader";
import { useTranslation } from "react-i18next";
import Gallery from "../../components/Gallery";
import { ThreeDRotation } from "@mui/icons-material";
import ThreeDViewer from "../../components/ThreeDViewer";

function BeefSubprimal() {
  const language = useSelector(state => state.language);
  const { retailer } = useContext(RetailerContext);
  const { t } = useTranslation();

  const { beefProductId } = useParams();

  const fields = [
    'beef_product_brandings.beef_product_branding_id.brand_color',
    'beef_product_brandings.beef_product_branding_id.id',
    'beef_product_brandings.beef_product_branding_id.image',
    'beef_product_brandings.beef_product_branding_id.logo',
    'beef_product_brandings.beef_product_branding_id.translations.description',
    'beef_product_brandings.beef_product_branding_id.translations.id',
    'beef_product_brandings.beef_product_branding_id.translations.languages_code',
    'beef_product_brandings.beef_product_branding_id.translations.name',
    'beef_product_brandings.beef_product_branding_id.retailers.retailer_id',
    'beef_product_brandings.beef_product_branding_id.retailer',
    'beef_product_brandings.beef_product_branding_id.status',
    'beef_product_overrides.image',
    'beef_product_overrides.retailer',
    'beef_product_overrides.status',
    'beef_product_overrides.svg_icon',
    'beef_product_overrides.translations.description',
    'beef_product_overrides.translations.languages_code',
    'beef_product_overrides.translations.name',
    'catalog_code',
    'collections.*.*',
    'children.beef_product_overrides.*',
    'children.beef_product_overrides.translations.*',
    'children.image',
    'children.*.*',
    'children.recipes.recipe_id.retailers',
    'cooking_methods.id',
    'cooking_methods.cooking_method.translations.id',
    'cooking_methods.cooking_method.translations.languages_code',
    'cooking_methods.cooking_method.translations.Method',
    'cooking_methods.Recommended',
    'cooking_methods.translations.id',
    'cooking_methods.translations.languages_code',
    'cooking_methods.translations.Directions',
    'external_url',
    'id',
    'image',
    'merchandising_sections.*',
    'merchandising_sections.item.id',
    'merchandising_sections.item.status',
    'merchandising_sections.item.translations.*',
    'merchandising_sections.item.videos.id',
    'merchandising_sections.item.videos.status',
    'merchandising_sections.item.videos.translations.*',
    'key_information_blocks.*',
    'key_information_blocks.item.id',
    'key_information_blocks.item.status',
    'key_information_blocks.item.translations.*',
    'key_information_blocks.item.videos.id',
    'key_information_blocks.item.videos.status',
    'key_information_blocks.item.videos.translations.*',
    'Nutrients.calories',
    'Nutrients.id',
    'Nutrients.translations.id',
    'Nutrients.translations.description',
    'Nutrients.translations.languages_code',
    'Nutrients.translations.macronutrients',
    'Nutrients.translations.micronutrients',
    'Nutrients.translations.portion_size',
    'sku_blank',
    'sku_annotated',
    'svg_icon',
    'translations.id',
    'translations.description',
    'translations.languages_id',
    'translations.name',
    'translations.specifications',
    'translations.tips',
    'translations.vimeo',
    'translations.youtube',
    'visual_guide_blocks.*',
    'visual_guide_blocks.item.id',
    'visual_guide_blocks.item.images.id',
    'visual_guide_blocks.item.images.status',
    'visual_guide_blocks.item.images.translations.*',
    'visual_guide_blocks.item.status',
    'visual_guide_blocks.item.translations.*',
    'visual_guide_blocks.item.videos.id',
    'visual_guide_blocks.item.videos.status',
    'visual_guide_blocks.item.videos.translations.*',
    'primal_configurations.*',
    'primal_configurations.visual_guide_images.directus_files_id.id',
    'primal_configurations.visual_guide_images.directus_files_id.type',
  ];

  const { data:beefProduct, error, isLoading } = useSWR({
      url: `beef_product/${beefProductId}`,
      fields,
    }, fetcher);
  
  useUpdateDocumentMeta(beefProduct?.name);

  let primalCutData = {};

  let visualGuideImages = [];
  let visualGuideVideos = [];
  let purchasingVideos = [];
  let merchandisingVideos = [];

  if (!!beefProduct) {
    primalCutData = getTranslation(
      beefProduct,
      {
        key: 'languages_code',
        code: language.code
      },
      'primal_configurations',
    );

    visualGuideImages = primalCutData?.visual_guide_images
      ?.filter(imageBlock => imageBlock?.directus_files_id?.type?.startsWith('image'))
      ?.filter(imageBlock => !!imageBlock?.directus_files_id?.id)
      ?.map(imageBlock => {
        const imageUrl = `${process.env.REACT_APP_API_URL}/assets/${imageBlock.directus_files_id.id}?key=article-image`;

        return {
          id: imageBlock.id,
          imageUrl,
        }
      }) || [];
    
    visualGuideVideos = primalCutData?.visual_guide_videos
      ?.map(video => {
        const sourceUrl = video.type === 'VIMEO' ?
          `${constants.VIMEO_PLAYER_URL}/${video.video_id}` :
          `${constants.YOUTUBE_PLAYER_URL}/${video.video_id}`

        return {
          id: video.id,
          sourceUrl,
          videoType: video.type,
        }
      }) || [];
    
    purchasingVideos = primalCutData?.purchasing_videos
      ?.map(video => {
        const sourceUrl = video.type === 'VIMEO' ?
          `${constants.VIMEO_PLAYER_URL}/${video.video_id}` :
          `${constants.YOUTUBE_PLAYER_URL}/${video.video_id}`

        return {
          id: video.id,
          sourceUrl,
          videoType: video.type,
        }
      }) || [];

    merchandisingVideos = primalCutData?.merchandising_videos
      ?.map(video => {
        const sourceUrl = video.type === 'VIMEO' ?
          `${constants.VIMEO_PLAYER_URL}/${video.video_id}` :
          `${constants.YOUTUBE_PLAYER_URL}/${video.video_id}`

        return {
          id: video.id,
          sourceUrl,
          videoType: video.type,
        }
      }) || [];
    
    const translatedBeef = getTranslation(
      beefProduct,
      {
        key: 'languages_id',
        code: language.code
      }
    );
        

    // handle beef name & description overrides
    beefProduct.name = getTranslation(
      getBeefProductOverride(beefProduct, retailer, language),
      {
        key: 'languages_code',
        code: language.code
      }).name ||
      getTranslation(
        beefProduct,
        {
          key: 'languages_id',
          code: language.code
        }).name;

    // append catalogue code
    const catalogCodeText = beefProduct.catalog_code ? ` (${beefProduct.catalog_code})` : '';
    beefProduct.name = `${beefProduct.name} ${catalogCodeText}`;

    beefProduct.description = getTranslation(
      getBeefProductOverride(beefProduct, retailer, language),
      {
        key: 'languages_code',
        code: language.code
      }).description ||
      getTranslation(
        beefProduct,
        {
          key: 'languages_id',
          code: language.code
        }).description;

    // handle beef image & icon overrides
    beefProduct.image = getBeefProductOverride(beefProduct, retailer, language).image || beefProduct.image;
    beefProduct.svg_icon = getBeefProductOverride(beefProduct, retailer, language).svg_icon || beefProduct.svg_icon;

    // specifications foldable
    beefProduct.specifications = getTranslation(
      getBeefProductOverride(beefProduct, retailer, language),
      {
        key: 'languages_code',
        code: language.code
      }).specifications ||
      getTranslation(
        beefProduct,
        {
          key: 'languages_id',
          code: language.code
        }).specifications;

    // build videos data
    beefProduct.videos = [
      ...(
        translatedBeef.vimeo?.map(vimeo => {
          const id = vimeo.vimeo_id.replace('https://vimeo.com/', '');

          return {
            id,
            sourceUrl: `${constants.VIMEO_PLAYER_URL}/${id}`,
            videoType: 'vimeo',
          }
        }) || []
      ),
      ...(
        translatedBeef.youtube?.map(youtube => ({
          id: youtube.youtube_id,
          sourceUrl: `${constants.YOUTUBE_PLAYER_URL}/${youtube.youtube_id}`,
          videoType: 'youtube',
        })) || []
      )
    ];
  }

  if (isLoading) {
    return <Loader />
  }
  console.log(beefProduct.beef_product_brandings)
  console.log(retailer)
  return (
    <Box
      className="beef-product"
    >
      <Container maxWidth disableGutters
        sx={{
          p: 0
        }}
      >
        <Box>
          <PageHeader
            item={{ 
              name: beefProduct?.name,
            }}
            useAsLink={false}
          />
        </Box>
      </Container>

      <Container
        fixed
        className='padding-on-mobile'
        sx={{
            mt: 5
        }}
      >
        <Box
          sx={{
            mb: 3,
          }}
        >
            <Typography
                className='search-message'
                sx={{
                    color: '#000',
                    fontWeight: 300,
                    fontSize: '18px',
                    textAlign: 'justify'
                }}
                component="div"
            >
                <DescriptionHolder content={beefProduct?.description} />
            </Typography>
        </Box>
        
        { !!beefProduct?.image &&
          <Stack
              alignItems={'center'}
              justifyContent={'center'}
          >
              <Grid
                  className='beef-product_img_container'
                  item
                  sm={12}
                  sx={{
                    mb: 3,
                    textAlign: 'center'
                  }}
              >
                  <img
                    className={styles.beef_product_img}
                    src={`${process.env.REACT_APP_API_URL}/assets/${beefProduct.image}?key=page-hero-image-contain`}
                    alt={beefProduct?.name} 
                  />
              </Grid>
          </Stack>
        }
      </Container>

      <Container fixed
        sx={{
          mb: 3,
          px: {
            xs: 0,
            md: '1.5rem'
          }
        }}
      >
        <Grid container
          sx={{
            mb: 5,
          }}
        >
        { 
          beefProduct.beef_product_brandings?.map(beefProductBrand => (
            beefProductBrand.beef_product_branding_id != null &&
            beefProductBrand.beef_product_branding_id.retailers.length > 0 &&
            beefProductBrand.beef_product_branding_id.status == 'published' &&
            beefProductBrand.beef_product_branding_id.retailers.find(e => e.retailer_id == retailer.id) ?
            <Grid item
                key={beefProductBrand.id}
                xs={12}
            >
                <BeefProductBrand item={beefProductBrand.beef_product_branding_id} />
            </Grid> :
            null  ))
        }
        </Grid>

        {
          !!primalCutData.key_information &&
          <AccordionSection
            title={t('keyInformation')}
            expanded={true}
          >
            <Box
              sx={{
                mt: 1
              }}
            >
              <Typography
                component={'div'}
              >
                <Wysiwyg>
                  {primalCutData.key_information}
                </Wysiwyg>
              </Typography>
            </Box>
          </AccordionSection>
        }

        {
          !!beefProduct?.Nutrients &&
          <AccordionSection
            title={t('nutrition')}
            expanded={true}
          >
            <Box>
              <Nutrition
                nutrition="beefProduct.Nutrients" />
            </Box>
          </AccordionSection>
        }

        {
          (!!primalCutData.external_url || !!visualGuideImages.length || !!visualGuideVideos.length) &&
          <AccordionSection
            title={t('visualGuides')}
            expanded={true}
          >
            <Box
              sx={{
                mt: 1,
              }}
            >
              <Gallery
                variant={'grid'}
                imageBlocks={visualGuideImages}
              />
              <Box
                sx={{
                  textAlign: 'center',
                }}
              >
                <Videos
                  sx={{
                    p: 0,
                  }}
                  mode='light'
                  videos={visualGuideVideos}
                />
              </Box>

              {
                !!primalCutData.external_url &&
                <Stack
                  direction={'row'}
                  spacing={1}
                  alignItems={'center'}
                  justifyContent={'center'}
                  sx={{
                    textAlign: 'center',
                    mt: 3,
                    color: '#000'
                  }}
                >
                  <ThreeDRotation
                    fontSize="large"
                  />

                  <a href={primalCutData.external_url} target="_blank" rel="noreferrer">
                    <Typography
                      component={'span'}
                      sx={{
                        color: '#000',
                        textDecoration: 'underline',
                      }}
                    >
                      {t('visualGuideExternalLink')}
                    </Typography>
                  </a>
                </Stack>
              }
            </Box>
          </AccordionSection>
        }

        {
          (!!primalCutData.purchasing_content || !!purchasingVideos.length) &&
          <AccordionSection
            title={t('purchasing')}
            expanded={true}
          >
            <Box
              sx={{
                mt: 1
              }}
            >
              <Typography
                component={'div'}
              >
                <Wysiwyg>
                  {primalCutData.purchasing_content}
                </Wysiwyg>
              </Typography>

              <Box
                sx={{
                  textAlign: 'center',
                }}
              >
                <Videos
                  sx={{
                    p: 0,
                  }}
                  mode='light'
                  title=""
                  videos={purchasingVideos}
                />
              </Box>
            </Box>
          </AccordionSection>
        }

        {
          (beefProduct?.sku_blank || beefProduct?.sku_annotated) &&
          <AccordionSection
            id={'3D'}
            title={t('3D')}
            expanded={true}
            sx={{}}
          >
            <Grid
              container
              spacing={2}
              sx={{
                mb: 2
              }}
            >
              <Grid item xs={12}>
                <ThreeDViewer
                  skuBlank={beefProduct?.sku_blank}
                  skuAnnotated={beefProduct?.sku_annotated}
                />
              </Grid>
            </Grid>
          </AccordionSection>
        }

        {
          (!!primalCutData.merchandising_content || !!merchandisingVideos.length || !!beefProduct?.children?.length) &&
          <AccordionSection
            title={t('merchandising')}
            expanded={true}
          >
            <Box
              sx={{
                mt: 1
              }}
            >
              <Typography
                component={'div'}
              >
                <Wysiwyg>
                  {primalCutData.merchandising_content}
                </Wysiwyg>
              </Typography>

              <Box
                sx={{
                  textAlign: 'center',
                  my: 2,
                }}
              >
                <Videos
                  sx={{
                    p: 0,
                  }}
                  mode='light'
                  title=""
                  videos={merchandisingVideos}
                />
              </Box>

              <Grid container>
                {
                  beefProduct?.children
                    ?.sort((a, b) => {
                      const nameA = getTranslation(a, { key: 'languages_id', code: language.code }).name;
                      const nameB = getTranslation(b, { key: 'languages_id', code: language.code }).name;

                      if (nameA < nameB) {
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }

                      // names must be equal
                      return 0;
                    })
                    ?.map(beefCut => (
                    <Grid item
                      xs={12}
                      md={6}
                    >
                      <TileCard
                        key={beefCut.id}
                        url={`/recipe-list/${beefCut.id}`}
                        title={getTranslation(beefCut, { key: 'languages_id', code: language.code }).name}
                        subtext={getTranslation(beefCut, { key: 'languages_id', code: language.code }).specifications}
                        imageUrl={beefCut?.image?.id ? `${process.env.REACT_APP_API_URL}/assets/${beefCut.image.id}?key=tile-background-image-beef-wholesale` : null}
                      />
                    </Grid>
                  ))
                }
              </Grid>
            </Box>
          </AccordionSection>
        }
      </Container>
    </Box>
  )
}

export default BeefSubprimal